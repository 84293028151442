import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
    Button,
    Confirm,
    DateInput,
    FileField,
    FileInput,
    SelectInput,
    TextInput,
    downloadCSV,
    required,
    useRefresh,
    useNotify
} from 'react-admin'
import { unparse as convertToCSV } from 'papaparse'
import Auth from '@aws-amplify/auth'
import Storage from '@aws-amplify/storage'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'
import UserIcon from '@material-ui/icons/Group'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import { Typography } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'

import { Headers, makeHeaders } from './DataProvider/utils'


interface ExportDataProps {
    getDataFn?: Function
    fields: string[]
    name?: string
    resourceUrl: string
    style?: any
}

interface GoToUserProps {
    history: any
    uid?: string
}

interface ResetPasswordProps {
    enabled?: boolean
    url?: string
}

interface UploadFileProps {
    label: string
    upload_url: string
    user_id: string
    // session: string
    type?: 'quote' | 'policy'
}

const addtlCarriers = [
    'Appalachian',
    'AU Gold',
    'Cowbell',
    'First',
    'Foxquilt',
    'RAINBOW',
    'Slice',
    'SmartChoice',
    'Thimble',
    'Three',
    'USLI',
    'Hanover',
    'CNA',
    'Travelers',
    'Guard',
    'Hippo',
    'Burns & Wilcox',
    'Simply Business',
    'Pie',
    'AmSuisse'
]
const CARRIERS = [
    { id: 'apogee', name: 'Apogee' },
    { id: 'attune', name: 'Attune' },
    { id: 'amwins', name: 'AMWINS' },
    { id: 'biberk', name: 'Biberk' },
    { id: 'bristol-west', name: 'Bristol West' },
    { id: 'cf', name: 'Crum & Forster' },
    { id: 'chubb', name: 'Chubb' },
    { id: 'coterie', name: 'Coterie' },
    { id: 'coverwallet', name: 'Coverwallet' },
    { id: 'employers', name: 'Employers' },
    { id: 'hartford', name: 'Hartford' },
    { id: 'hiscox', name: 'Hiscox' },
    { id: 'next', name: 'Next' },
    { id: 'pathpoint', name: 'Pathpoint' },
    { id: 'philidelphia', name: 'Philidelphia' },
    { id: 'progressive', name: 'Progressive' },
].concat(addtlCarriers.map(carrier => {
    const id = carrier.toLowerCase().replace(' &', '').replace(' ', '-')
    return {
        id,
        name: carrier
    }
}))
CARRIERS.sort((a, b) => {
    if (a.name > b.name) return 1
    if (a.name < b.name) return -1
    return 0
})

const PRODUCTS = [
    { id: 'bop', name: 'BOP' },
    { id: 'gl', name: 'GL' },
    { id: 'pl', name: 'PL' },
    { id: 'wc', name: 'WC' },
    { id: 'commercial-auto', name: 'Commercial Auto' }
]


export const DownloadFileButton = (props: any) => {
    const { url } = props
    const notify = useNotify()
    const downloadFile = () => {
        Auth.currentSession().then((resp: any) => {
            const accessJwt = resp.getAccessToken().getJwtToken()
            const headers: Headers = makeHeaders(accessJwt)

            axios.get(url, { headers })
                .then(
                    ({ data }) => {
                        const { url } = data['data']
                        const link = document.createElement('a')
                        link.href = url
                        link.download = 'file.pdf'
                        link.dispatchEvent(new MouseEvent('click'))
                        notify('Success!')
                    }
                )
                .catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', 'warning');
                })
        })
    }
    return (
        <Button label="Download" onClick={() => downloadFile()}>
            <GetAppIcon />
        </Button>
    )
}


export const ExportDataButton = ({ getDataFn, fields, name, resourceUrl, style }: ExportDataProps) => {
    const notify = useNotify()
    const exportToCSV = () => {
        Auth.currentSession().then((resp: any) => {
            const accessJwt = resp.getAccessToken().getJwtToken()
            const headers: Headers = makeHeaders(accessJwt)
            axios.get(resourceUrl, { headers })
                .then(
                    ({ data }) => {
                        let content
                        if (getDataFn) {
                            content = getDataFn(data)
                        } else {
                            content = data['data']['data']
                        }
                        const csv = convertToCSV({
                            data: content,
                            fields
                        })
                        const filename = name || 'download'
                        const now = new Date()
                        downloadCSV(csv, `${filename}.${now.toISOString().split('T')[0]}`)
                        notify('Success!')
                    }
                )
                .catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', 'warning');
                });
        })
    }

    return (
        <Button label="Export to CSV" onClick={exportToCSV}>
            <GetAppIcon />
        </Button>
    )
}

export const GoToUser = ({ history, uid }: GoToUserProps) => {
    return (
        <Button label="Go to Gilder" onClick={() => history.push(`/gilders/${uid}`)}>
            <UserIcon />
        </Button>
    )
}


export const ResetPasswordButton = ({ enabled, url }: ResetPasswordProps) => {
    const [open, setOpen] = useState(false)
    const [shouldNotify, setShouldNotify] = useState(false)
    const refresh = useRefresh()
    const notify = useNotify()

    const handleClick = () => setOpen(true)
    const handleDialogClose = () => setOpen(false)

    const handleConfirm = () => {
        Auth.currentSession().then((resp: any) => {
            const accessJwt = resp.getAccessToken().getJwtToken()
            const headers: Headers = makeHeaders(accessJwt)
            // If user is not Enabled == false (which will throw a cognito error), request a reset
            if (url && enabled !== false) {
                axios.get(url, { headers })
                    .then(res => console.log(res))
                    .catch(err => console.error(err))
                refresh()
                notify('Password reset sent!')
            }
            setOpen(false)
        })
    }

    useEffect(() => {
        if (shouldNotify) {
            notify('Oops! We can\'t send a password reset to an inactive user')
            setShouldNotify(false)
        }
    }, [shouldNotify, notify])

    if (open && enabled === false) {
        setOpen(false)
        setShouldNotify(true)
        return (
            <></>
        )
    }

    return (
        <>
            <Button label="Reset Password" onClick={handleClick}>
                <SettingsBackupRestoreIcon />
            </Button>
            <Confirm
                isOpen={open}
                title="Reset User Password?"
                content="The next time the user signs in they will be forced to change their password. Are you sure you want to continue?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    )
}


export const UploadFileButton = ({ label, upload_url, user_id }: UploadFileProps) => {
    const [open, setOpen] = useState(false)
    const refresh = useRefresh()
    const notify = useNotify()

    const handleClick = () => setOpen(true)
    const handleDialogClose = () => setOpen(false)

    const handleConfirm = () => {
        const carrierSelect: any = document.querySelector('[name=carrier]')
        const expiresOn: any = document.querySelector('[name=expires_on]')
        const yearlyPremium: any = document.querySelector('[name=yearly_premium]')
        const policyNumber: any = document.querySelector('[name=policy_number]')
        const productSelect: any = document.querySelector('[name=product]')
        const uploadFiles: any = document.querySelector('#files')
        const dragAndDrop: any = document.querySelector('#src')

        if (!carrierSelect.value) {
            notify('Please select a carrier before uploading')
            return
        }
        if (!expiresOn.value) {
            notify('Please set an expiration date before submitting')
        }
        if (!uploadFiles.files) {
            notify('Please upload a quote or policy file before submitting')
            return
        }

        Auth.currentSession().then(async (resp: any) => {
            const accessJwt = resp.getAccessToken().getJwtToken()
            const headers: Headers = makeHeaders(accessJwt)
            headers['Content-Type'] = 'multipart/form-data'

            const formData = new FormData()
            const id = uuidv4()
            const anonymizedFilename = `${id}.pdf`
            formData.append('id', id)
            formData.append('filename', `public/${anonymizedFilename}`)
            formData.append('policy_number', policyNumber.value)
            formData.append('quote_id', policyNumber.value)
            formData.append('carrier', carrierSelect.value)
            formData.append('product', productSelect.value)
            formData.append('flow_type', 'manual')
            formData.append('yearly_premium', `${parseFloat(yearlyPremium.value) * 100}`)
            formData.append('expires_on', expiresOn.value)
            formData.append('user', user_id)
            formData.append('session', `__backoffice__${resp['accessToken']['payload']['username']}`)

            setOpen(false)
            notify('Uploading...')

            await Storage.put(anonymizedFilename, uploadFiles.files[0], { contentType: uploadFiles.files[0].type, level: "public" });

            axios.post(upload_url, formData, { headers }).then(resp => {
                refresh()
                notify('Document successfully attached!')
            }).catch(err => {
                console.error(err)
                refresh()
                notify('Error attaching document!')
            })
        })
    }

    const now = new Date()
    now.setFullYear(now.getFullYear() + 1)
    const nextYear = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`

    return (
        <>
            <Button color="secondary" label={label} onClick={handleClick}>
                <PublishIcon />
            </Button>
            <Confirm
                isOpen={open}
                title={label}
                content={
                    <>
                        <Typography>Enter the quote or policy number</Typography>
                        <TextInput source="policy_number" label="Policy Number" fullWidth={true} validate={[required()]} />
                        <Typography>Choose the carrier</Typography>
                        <SelectInput source="carrier" label="Select Carrier" choices={CARRIERS} fullWidth={true} validate={[required()]} />
                        <Typography>Set the policy type</Typography>
                        <SelectInput source="product" label="Select Product" choices={PRODUCTS} fullWidth={true} validate={[required()]} />
                        <Typography>Set the expiration date</Typography>
                        <DateInput source="expires_on" label="Expires On" fullWidth={true} initialValue={nextYear} validate={[required()]} />
                        <Typography>Enter the annual policy premium in dollars</Typography>
                        <TextInput source="yearly_premium" label="Yearly Premium in dollars" fullWidth={true} validate={[required()] }/>
                        <FileInput source="files" label="Quote/Policy PDFs" accept="application/pdf" options={{noDrag: true}} placeholder="Click here to select a file to upload">
                            <FileField source="src" title="title" />
                        </FileInput>
                        <Typography>
                            This will attach a quote or policy to a Gild member's account. An email will be sent
                            alerting them that a new document has been attached to their account
                        </Typography>
                    </>
                }
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    )
}